import React, { useState } from 'react';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Card from '../components/common/Card';
import Layout from '../components/Layout';
import SearchInput from '../components/SearchInput';
import Selection from './../components/common/Selection';
import SEO from '../components/Seo';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import Select from 'react-select';
import sortBy from 'lodash.sortby';

const gradeLevelSortBy = [
  'K-2',
  '3-5',
  '6-8',
  'Pre-K',
  'Kindergarten',
  'Grade 1',
  'Grade 2',
  'Grade 3',
  'Grade 4',
  'Grade 5',
  'Grade 6',
  'Grade 7',
  'Grade 8'
];

interface TabProps {
  text: string;
  selected: boolean;
  onClick: any;
}

const Tab: React.FC<TabProps> = props => {
  const classNames = classnames({
    'py-2 mr-6 text-gray-700 cursor-pointer focus:outline-none': true,
    'text-primary font-semibold border-b-2 border-primary': props.selected
  });
  return (
    <button className={classNames} onClick={props.onClick}>
      {props.text}
    </button>
  );
};

interface Grade {
  value: number;
  label: string;
  selected: boolean;
}

type Year = {
  value: number;
  label: string;
  selected: boolean;
};

interface FilterSelectorProps {
  selections: Grade[];
  setSelections: Function;
}
interface SearchResultProps {
  result: ResourceItem;
}

const SearchResult: React.FC<SearchResultProps> = props => {
  return (
    <a href={props.result.downloadLink} target="_blank">
      <div className="rounded-lg w-full p-4 border-t border-gray-200 shadow">
        <div className="text-primary text-xl mb-3">
          <FontAwesomeIcon
            className="mr-2"
            icon={props.result.format === 'Video' ? faYoutube : faFilePdf}
          />
          <span className="font-semibold sans-serif text-primary hover:underline">
            {props.result.title}
          </span>
        </div>
        <div className="mb-2 flex">
          <div className="mr-6">
            <p className="text-gray-500 text-xs uppercase">Year</p>
            <p className="text-sm text-gray-900 sans-serif">
              {props.result.year}
            </p>
          </div>
          <div className="mr-6">
            <p className="text-gray-500 text-xs uppercase">Tab</p>
            <p className="text-sm text-gray-900 sans-serif">
              {props.result.tab}
            </p>
          </div>
          <div>
            <p className="text-gray-500 text-xs uppercase">Category/Section</p>
            <p className="text-sm text-gray-900 sans-serif">
              {props.result.categorysection}
            </p>
          </div>
        </div>
        <div>
          <p className="text-gray-500 text-xs uppercase">Grades</p>
          <p className="text-gray-900 text-sm sans-serif">
            {props.result.grade?.join(', ')}
          </p>
        </div>
      </div>
    </a>
  );
};

type ResourceItem = {
  title: string;
  year: string;
  categorysection: string;
  downloadLink: string;
  grade: string[];
  tab: string;
  tag: string[];
  format: string;
  level: string;
};

interface ResourceResultsProps {
  searchTerm: string;
  resourceItems: ResourceItem[];
}

const ResourceResults: React.FC<ResourceResultsProps> = props => {
  const filteredResourceItems = props.resourceItems.filter(r =>
    props.searchTerm
      ? r.title.toLowerCase().includes(props.searchTerm.toLowerCase())
      : r
  );
  return (
    <div className="w-full">
      {!filteredResourceItems.length ? (
        <p className="text-center text-xl text-gray-700">
          No resources to display
        </p>
      ) : (
        <>
          {filteredResourceItems.map((r: ResourceItem, i: number) => (
            <div className="mb-4" key={i}>
              <SearchResult result={r} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

type Format = {
  selected: boolean;
  label: string;
};

type Formats = {
  documents: Format;
  videos: Format;
};

interface FormatSelectorProps {
  onChange: any;
}
const FormatSelector: React.FC<FormatSelectorProps> = props => {
  const [formats, setFormats] = useState<Formats>({
    documents: {
      selected: true,
      label: 'Documents'
    },
    videos: {
      selected: true,
      label: 'Videos'
    }
  });

  return (
    <>
      <p className="text-gray-500 font-bold mb-2 text-lg">Resource Formats</p>
      <Selection
        item={formats.documents}
        setSelected={() => {
          const updatedFormats = {
            ...formats,
            documents: {
              selected: !formats.documents.selected,
              label: 'Documents'
            }
          };
          setFormats(updatedFormats);
          props.onChange(updatedFormats);
        }}
      />
      <Selection
        item={formats.videos}
        setSelected={() => {
          const updatedFormats = {
            ...formats,
            videos: {
              selected: !formats.videos.selected,
              label: 'Videos'
            }
          };
          setFormats(updatedFormats);
          props.onChange(updatedFormats);
        }}
      />
    </>
  );
};

type SelectOption = {
  value: string;
  label: string;
};

interface FieldFilterProps {
  name: string;
  options: SelectOption[];
  onChange: any;
}
const FieldFilter: React.FC<FieldFilterProps> = props => {
  return (
    <>
      <p className="text-gray-500 font-bold mb-2 text-lg">{props.name}</p>
      <Select
        isMulti={true}
        options={props.options}
        onChange={props.onChange}
      />
    </>
  );
};

const ResourceCenter: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulResourceItem(
        filter: {
          node_locale: { eq: "en-US" }
          visibility: { ne: "Private Only" }
        }
        sort: { fields: title, order: ASC }
      ) {
        edges {
          node {
            title
            downloadLink
            categorysection
            year
            tab
            grade
            format
            level
            tab
            tag
          }
        }
      }
    }
  `);

  const resourceItems = data.allContentfulResourceItem.edges.map(
    (edge: any) => edge.node
  );

  const getUniqueOptions = (
    items: ResourceItem[],
    key: string,
    isArray: boolean = false
  ): SelectOption[] => {
    const allItems = items
      .map((item: ResourceItem) => item[key])
      .filter((item: string) => !!item);

    let preuniqueItems;

    if (isArray) {
      preuniqueItems = allItems.flat();
    } else {
      preuniqueItems = allItems;
    }

    return [...new Set(preuniqueItems)].map((item: string) => ({
      value: item,
      label: item
    }));
  };

  const getSortedItems = (sortByArray: string[], items: any) => {
    return sortBy(items, (item: any) => sortByArray.indexOf(item.label));
  };

  const [visibleResourceItems, setVisibleResourceItems] = useState(
    resourceItems
  );
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Layout>
      <SEO title="Resource Center" />
      <div className="p-2 sm:p-8">
        <Card>
          <h1 className="font-bold text-gray-800 text-3xl">Resource Center</h1>

          <div className="flex flex-col lg:flex-row">
            <div className="mt-4 w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="md:mb-6">
                <FormatSelector
                  onChange={(formats: Formats) => {
                    const visibleFormats: string[] = [];

                    if (formats.documents.selected) {
                      visibleFormats.push('Document');
                    }
                    if (formats.videos.selected) {
                      visibleFormats.push('Video');
                    }
                    setVisibleResourceItems(
                      resourceItems.filter((r: ResourceItem) =>
                        visibleFormats.some((vf: string) => vf === r.format)
                      )
                    );
                  }}
                />
                <div className="mt-4">
                  <FieldFilter
                    name="Grade"
                    options={getSortedItems(
                      gradeLevelSortBy,
                      getUniqueOptions(resourceItems, 'grade', true)
                    )}
                    onChange={(selections: any) => {
                      if (selections) {
                        const grades = selections.map(
                          (s: SelectOption) => s.value
                        );

                        if (!grades?.length) {
                          setVisibleResourceItems(resourceItems);
                          return;
                        }

                        setVisibleResourceItems(
                          resourceItems.filter(
                            (r: ResourceItem) =>
                              r.grade && r.grade.some(g => grades.includes(g))
                          )
                        );
                      } else {
                        setVisibleResourceItems(resourceItems);
                        return;
                      }
                    }}
                  />
                </div>
                <div className="mt-4">
                  <FieldFilter
                    name="Reading Levels, A-Z"
                    options={getUniqueOptions(resourceItems, 'level')}
                    onChange={(selections: any) => {
                      const levels = selections.map(
                        (s: SelectOption) => s.value
                      );

                      if (!levels?.length) {
                        setVisibleResourceItems(resourceItems);
                        return;
                      }
                      setSelectedLevels(selectedLevels);
                      setVisibleResourceItems(
                        resourceItems.filter((r: ResourceItem) =>
                          levels.includes(r.level)
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:mt-4 w-full lg:w-3/4 p-4">
              <div className="w-72">
                <SearchInput
                  placeholder="Filter resources by title"
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <ResourceResults
                  resourceItems={visibleResourceItems}
                  searchTerm={searchTerm}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default ResourceCenter;
