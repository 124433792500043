import React from 'react';
import classnames from 'classnames';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type SelectionItem = {
  selected: boolean;
  label: string;
};

interface SelectionProps {
  item: SelectionItem;
  setSelected: any;
}

const Selection: React.FC<SelectionProps> = props => {
  const buttonClasses = classnames({
    'mb-1 p-2 bg-gray-100 cursor-pointer flex items-center w-full focus:outline-none text-left rounded': true,
    'text-gray-500': !props.item.selected,
    'bg-primary text-gray-200': props.item.selected
  });

  return (
    <button className={buttonClasses} onClick={props.setSelected}>
      <FontAwesomeIcon
        className="text-sm mr-2"
        icon={props.item.selected ? faCheckCircle : faCircle}
      />
      <p>{props.item.label}</p>
    </button>
  );
};

export default Selection;
